.slide-related-service .slick-slide > div {
  margin: 0 24px;
}

.slide-related-service .slick-prev:before,
.slide-related-service .slick-next:before {
  color: black;
}

.slide-related-service .slick-prev {
  left: -15px;
}
.slide-related-service .slick-next {
  right: -15px;
}

.slide-related-service .slick-list .slick-track {
  /* width: 100% !important; */
  margin-left: 0 !important;
}
