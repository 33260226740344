.slide-small .slick-slide > div {
  margin: 0 7px;
}

.slide-small .slick-prev:before,
.slide-small .slick-next:before {
  color: black;
}

.slide-small .slick-prev {
  left: 10px;
  z-index: 99;
}
.slide-small .slick-next {
  right: 10px;
  z-index: 99;
}
