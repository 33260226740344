.pac-container {
  background-color: #fff;
  position: absolute !important;
  top: 11% !important;
  width: 225px !important;
  left: 270px !important;
  border-radius: 10px;
  border: 1px solid rgb(207, 207, 207);
  box-shadow: rgba(28, 28, 28, 0.08) 0px 2px 8px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 99999999;
  padding-bottom: 12px;
}

@media only screen and (max-width: 1200px) {
  .pac-container {
    width: 185px !important;
    top: 28% !important;
  }
}

@media only screen and (max-width: 900px) {
  .pac-container {
    width: 85% !important;
    top: 21% !important;
    left: 27px !important;
  }
}
@media only screen and (max-width: 350px) {
  .pac-container {
    width: 85% !important;
    top: 45% !important;
    left: 27px !important;
  }
}

.pac-logo:after {
  content: "";
  padding: 1px 1px 1px 0;
  height: 16px;
  text-align: right;
  display: block;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 120px 14px;
}
.hdpi.pac-logo:after {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png);
}
.pac-item {
  cursor: default;
  padding: 7px 14px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  /* border-top: 1px solid #e6e6e6; */
  font-size: 11px;
  color: #999;
}
.pac-item:hover {
  background-color: #fafafa;
}
.pac-item-selected,
.pac-item-selected:hover {
  background-color: #ebf2fe;
}
.pac-matched {
  font-weight: 700;
}
.pac-item-query {
  font-size: 13px;
  padding-right: 3px;
  color: #000;
}
.pac-icon {
  width: 15px;
  height: 20px;
  margin-right: 10px;
  margin-top: 6px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-size: 34px;
}
.hdpi .pac-icon {
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png);
}
.pac-icon-search {
  background-position: -1px -1px;
}
.pac-item-selected .pac-icon-search {
  background-position: -18px -1px;
}
.pac-icon-marker {
  background-position: -1px -161px;
}
.pac-item-selected .pac-icon-marker {
  background-position: -18px -161px;
}
.pac-placeholder {
  color: gray;
}
